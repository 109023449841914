.host-adress{
    font-style: italic;
}

.host-title{
    font-family: area-normal, sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #000000;
}

.host-title:hover{
    color: #000000;
    font-weight: 800;
}

.host-events{

}

.host-events-title{

}