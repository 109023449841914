.bannerImage {
    max-width: 50%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 767px) {
    .bannerImage {
      max-width: 80%;
      height: auto;
    }
  }
  