.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.navItem {
  /* margin: 0 calc(10px + 2vw); */
}

.navLink, .languageButton {
  font-family: area-normal, sans-serif;
font-weight: 400;
font-style: normal;
  text-decoration: none;
  color: #333;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}

.navLink:hover, .languageButton:hover {
  color: #000000;
  font-weight: bold;
}

.active {
  color: #007bff;
}

.navContainer {
  /* max-width: 1200px; */
  width: 100%;
  margin: 0 auto;
}  

@media screen and (max-width: 767px) {
  .navContainer {
    width: 95%;
    margin: 0 auto;
  }
}

.buttonsWrapper {
  display: inline-block;
  border-bottom: 1px solid #000; /* Adjust the color and thickness as needed */
  padding-bottom: 10px; /* Adjust the value as needed */
  width: 100%;
}
