.artist-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
  }

  a.artist-row, a.artist-row * {
    color: inherit;
    text-decoration: none;
  }
  
  a.artist-row:hover, a.artist-row:hover * {
    color: inherit;
    text-decoration: none;
  }
  
  
  .row-odd {
    flex-direction: row-reverse;
  }
  
  .artist-image,
  .artist-info {
    flex: 1;
    margin: 10px;
  }
  
  .artist-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .artist-title {
    font-family: area-normal, sans-serif;
  font-weight: 700;
  font-style: normal;
    font-size: 1.5em;
    margin-bottom: 0.5em;
    text-align: left;
  }
  
  .text-container {
    font-size: 1em;
  }
  
  
  .artist-image img {
    width: 300px;  /* adjust as needed */
    height: 300px;  /* adjust as needed */
    object-fit: cover;  /* this will make the image cover the specified width and height while maintaining its aspect ratio */
  }
  
  @media screen and (max-width: 767px) {
    .artist-row {
      flex-direction: column;
    }
  
    .artist-image img {
      width: 100%;  /* adjust as needed */
      height: auto;  /* adjust as needed */
    }
  }
  