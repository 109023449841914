html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content-wrapper {
  flex: 1 0 auto;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  align-items: center;
}

.content-section {
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 100px;
}

.text-container {
  font-family: 'Charter Regular', serif;
  word-wrap: break-word;
  text-align: justify;
}

.title {
  font-family: area-normal, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2em;
  text-align: left;
}

.title-small {
  font-family: area-normal, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2em;
  text-align: left;
}

.title-centered {
  font-family: area-normal, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2em;
  text-align: center;
}

.title-maplink {
  color: #000000;
  font-family: area-normal, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.3em;
  text-align: center;
  width: 50%; /* This is the new line */
  margin: auto; 
}

.title-maplink:hover {
  color: #000000;
  font-family: area-normal, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 1.3em;
  text-align: center;
  text-decoration: underline;
}

.subheading{
  width: 70%;
  margin: auto;
}

.responsiveImage {
  max-width: 100%;
  height: auto;
}

body {
  font-family: 'Charter Regular', serif;
  font-weight: 400;
  font-style: normal;
  text-align: justify;
}

.page-content {
  margin-top: 20px; /* Adjust the value to increase or decrease the spacing */
}

.header {
  margin: 0 auto;
  margin-bottom: 50px; /* Adjust the value to increase or decrease the spacing */
  max-width: 700px;
}

.taglineBold
{
  font-family: area-normal, sans-serif;
font-weight: 700;
font-style: normal;
  font-size: 3em;
}

.taglineCursive
{
  font-family: swear-display, serif;
font-weight: 600;
font-style: italic;
  font-size: 3em;
  letter-spacing: 4px;
}

.artworktitle {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}

/* Media query for mobile devices */
@media screen and (max-width: 767px) {
  .nav-container {
    flex-direction: column;
  }

  .nav-item {
    margin: 5px 0;
  }

  .taglineBold
{
  font-size: 2em;
}
.taglineCursive
{
  font-size: 2em;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.title-maplink{
  font-size: 1em;
}

.title-maplin:hover{
  font-size: 1em;
}

}

.image-container img {
  width: 97%;
  height: auto;
  margin-bottom: 40px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* for 16:9 ratio */
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


@font-face {
  font-family: 'Charter Regular';
  src: url('./fonts/charter_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charter Bold';
  src: url('./fonts/charter_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charter Italic';
  src: url('./fonts/charter_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Charter Bold Italic';
  src: url('./fonts/charter_bold_italic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}